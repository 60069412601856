body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.grid-container {
  background-color: #fff;
}

.press-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.press-container .press {
  border: 1px solid #ddd;
  margin: 4px;
}

.dates {
  min-height: 700px;
}
.date-drop {
  border: 1px solid #ddd;
  margin: 2px;
  padding: 0;
}

.date-drop h4 {
  padding: 0;
  margin: 8px 0;
}

table {
  background-color: #fff;
  width: 100%;
  /* min-width: 1900px; */
}

.split-pane-col {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.split-pane-row {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
}

.split-pane-top,
.split-pane-bottom {
  padding: 8px;
}

.separator-col {
  border: 5px solid red;
  cursor: col-resize;
}

.separator-row {
  border: 5px solid red;
  cursor: row-resize;
}
